/**
 * Helpers
 */
import {
  fromBreakpoint,
  onVisibilityChange, toBreakpoint
} from './helpers'

/**
 * Web standards
 */
import './cookieconsent'
import './langswitcher'
import './rellax'
import './lazyload'
import './videoConstructor'
import 'alpinejs/dist/cdn.min.js';

/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector( '.wpcf7-form' );
if ( wpcf7forms ) {
  const jquery = import( 'jquery' );
  global.jQuery = require( 'jquery' );
  window.jQuery = require( 'jquery' );
}

/**
 * Dynamic import scripts
 */
const elements = [
  '#progress--1',
  '#trustedby',
  '#cases',
  '#method',
  '.cases-archive',
  '.sectors-archive',
  '.services-archive',
  '.wpcf7',
  '.method-page',
  '.vacancies-archive'
];

[].forEach.call(elements, (element) => {

  if (element !== '') {

    if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

      const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

        if (element === '#progress--1' && fromBreakpoint( '600px' )) {
          const progress = import('./progressbar');
        }

        if (element === '#trustedby') {
          const trustedby = import('./trustedby');
        }

        if (element === '#cases') {
          const solutions = import('./cases--home');
        }

        if (element === '#method' ) {
          const method = import('./methods');
        }

        if (element === '.cases-archive') {
          const method = import('./cases--archive');
        }

        if (element === '.services-archive') {
          const method = import('./services');
        }

        if (element === '.sectors-archive') {
          const method = import('./sectors');
        }

        if (element === '.method-page') {
          const methodStyle = import('./../scss/_method.scss');
          const method = import('./methods--archive');
        }

        if (element === '.vacancies-archive') {
          const method = import('./vacancies');
        }

        if ( element === '.wpcf7' ) {
          const cf7scripts = import('./cf7');
        }

      })

      if (window.addEventListener) {
        addEventListener('DOMContentLoaded', lazyloadHandler, false);
        addEventListener('load', lazyloadHandler, false);
        addEventListener('scroll', lazyloadHandler, false);
        addEventListener('resize', lazyloadHandler, false);
      } else if (window.attachEvent) {
        attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
        attachEvent('onload', lazyloadHandler);
        attachEvent('onscroll', lazyloadHandler);
        attachEvent('onresize', lazyloadHandler);
      }
    }
  }

});

/**
 * Dynamic scroll to anchors with an preset offset
 * @link https://stackoverflow.com/a/13067009
 */
 (function(document, history, location) {
  var HISTORY_SUPPORT = !!(history && history.pushState);

  var anchorScrolls = {
    ANCHOR_REGEX: /^#[^ ]+$/,
    OFFSET_HEIGHT_PX: 200,

    /**
     * Establish events, and fix initial scroll position if a hash is provided.
     */
    init: function() {
      this.scrollToCurrent();
      window.addEventListener('hashchange', this.scrollToCurrent.bind(this));
      document.body.addEventListener('click', this.delegateAnchors.bind(this));
    },

    /**
     * Return the offset amount to deduct from the normal scroll position.
     * Modify as appropriate to allow for dynamic calculations
     */
    getFixedOffset: function() {
      return this.OFFSET_HEIGHT_PX;
    },

    /**
     * If the provided href is an anchor which resolves to an element on the
     * page, scroll to it.
     * @param  {String} href
     * @return {Boolean} - Was the href an anchor.
     */
    scrollIfAnchor: function(href, pushToHistory) {
      var match, rect, anchorOffset;
      if(!this.ANCHOR_REGEX.test(href)) {
        return false;
      }

      match = document.getElementById(href.slice(1));
      if(match) {
        rect = match.getBoundingClientRect();
        anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset();
        window.scrollTo(window.pageXOffset, anchorOffset);

        // Add the state to history as-per normal anchor links
        if(HISTORY_SUPPORT && pushToHistory) {
          history.pushState({}, document.title, location.pathname + href);
        }
      }

      return !!match;
    },

    /**
     * Attempt to scroll to the current location's hash.
     */
    scrollToCurrent: function() {

      this.scrollIfAnchor(window.location.hash);
    },

    /**
     * If the click event's target was an anchor, fix the scroll position.
     */
    delegateAnchors: function(e) {
      var elem = e.target;

      if(
        elem.nodeName === 'A' &&
        this.scrollIfAnchor(elem.getAttribute('href'), true)
      ) {
        e.preventDefault();
      }
    }
  };

  window.addEventListener(
    'DOMContentLoaded', anchorScrolls.init.bind(anchorScrolls)
  );
})(window.document, window.history, window.location);